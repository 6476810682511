<template>
    <div class="height1">
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item label="绩效周期" v-if="teamPerformanceInfo.isTeamPerformanceReward">
                                <span style="color:red;margin-right: 10px;">
                                    <span v-if="teamPerformanceInfo.performanceRewardCycle==1">按月统计</span>
                                    <span v-if="teamPerformanceInfo.performanceRewardCycle==2">按季统计</span>
                                    <span v-if="teamPerformanceInfo.performanceRewardCycle==3">按年统计</span>
                                </span>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入团长名称或手机号"
                                    @keyup.enter.native="search"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                        <el-row :gutter="20" v-if="teamPerformanceInfo.isTeamPerformanceReward">
                            <el-col :span="24">
                                <el-descriptions title="" direction="vertical"
                                    :column="teamPerformanceInfo.performanceRewardScale.length" border>
                                    <el-descriptions-item :label="`${item.startValue}-${item.endValue}`"
                                        v-for="(item,index) in teamPerformanceInfo.performanceRewardScale" :key="index">
                                        {{item.scopeRatio}}%</el-descriptions-item>
                                </el-descriptions>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <el-table :data="tableData" stripe height="calc(100% - 90px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="commander.userName" label="团长" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="commander.phone" label="团长账号" align="center">
                    </el-table-column>
                    <el-table-column prop="totalTransactionAmount" label="团总交易额" align="center">
                    </el-table-column>
                    <el-table-column prop="totalPerformanceAmount" label="团总奖励额" align="center">
                    </el-table-column>
                    <el-table-column prop="totalNumber" label="团总人数" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="最近一次分账" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.lastGrantTime | timefilters }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="120" align="center">
                        <template slot-scope="scope">
                            <span class="primary optionBtn" @click="toDetail(scope.row)">发放绩效</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb"
import { TeamPerformance } from "../../../components/HospitalDomain/TeamPerformance";
export default {
    name: "Bill",
    components: {
        bread
    },
    data() {
        var temPerformance = new TeamPerformance(this.TokenClient, this.Services.Authorization);
        return {
            TeamPerformanceDomain: temPerformance,
            tableData: [],
            searchForm: {
                performanceRewardCycle: '1',//123月季年
                keyWord: '',
            },
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            ShareDialog: false,
            formLabelWidth: '150px',
            teamPerformanceInfo: {
                isTeamPerformanceReward: false,
                performanceRewardCycle: 1,
                performanceRewardScale: []
            },
        }
    },
    mounted() {
        this.getTeamPerformanceInfo();
        this.getList();
    },
    methods: {
        toDetail(item) {
            this.$router.push({
                path: '/Finance/CommandeTeamPerformance',
                query: {
                    commanderId: item.commander.id,
                },
            })
        },
        search(event) {
            this.PageIndex = 1;
            this.getList()
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        getList() {
            var _this = this;
            var item = _this.searchForm;
            _this.TeamPerformanceDomain.GetTeamPerformance(item.keyWord, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results;
                    console.log(_this.tableData)
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        /**
         * 获取绩效设置
         */
        getTeamPerformanceInfo() {
            var _this = this;
            _this.TeamPerformanceDomain.GetTeamPerformanceInfo(new Date().getFullYear(), function (data) {
                _this.teamPerformanceInfo = data.data;
                if (!_this.teamPerformanceInfo.isTeamPerformanceReward) {
                    _this.$message({
                        type: 'warning',
                        message: '该店铺未开启团队绩效奖励'
                    });
                }
            }, function (err) {
                console.log(err);
                if (err.status == 400) {
                    _this.$message({
                        type: 'error',
                        message: '请前往设置团队绩效比例'
                    });
                }
            });


        },
    }
}
</script>

<style scoped>
.el-input {
    width: 95%;
}

.table-top {
    margin-bottom: 10px;
}

.iq-search-bar /deep/.el-form-item {
    margin-bottom: 10px;
}

.iq-search-bar /deep/.el-descriptions__header {
    margin-bottom: 10px;
}
</style>
